<template lang="pug">
  Auth(@loginFailed="onFailedAuthentication")
    Header
    Modal(
      @closeModal="closeModal"
      modalContentId="createClient"
      :showModal="showModal")
    div.wrap-clients
      ModuleClients(@openModal="openModal")
</template>

<style lang="scss" scoped>
.wrap-clients {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import Header from '@/components/shared/Header.vue'
import Modal from '@/components/shared/Modal.vue'
import ModuleClients from '@/components/module/ModuleClients.vue'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    Header,
    ModuleClients,
    Modal
  },
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication () {
      this.$router.push('/sign-in')
    },
    openModal () {
      this.showModal = true
    },
    closeModal (e) {
      this.showModal = false
    }
  }
}
</script>
