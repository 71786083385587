<template lang="pug">
  div.wrap-module-clients
    div(v-if="clients && clients[0]").py20
      div.wrap-h2.f.fm.mb12
        h2 会員様
        div(@click="$emit('openModal')").add-record-button.f.fm.px6.py4
          v-icon add
          span.pt2.pr4 会員様を追加
        //- v-btn(@click="$emit('openModal')" color="#2a2a2a" dark) 会員を追加
      div.wrap-cards
        div.clients-header.f.fm.px20.py8
          div.wrap-input.f.fm
            v-icon(size="20px").mr2 search
            input(v-model="nameForSearch" placeholder="名前を入力")
          div.wrap-selector.f.fm.mr12
            v-icon(size="20px") arrow_drop_down
            select(v-model="filterLabel")
              option(v-for="item in filterItems") {{item}}
        ItemClientCard(v-for="content in cards" :content="content")
        div(v-if="!cards[0]").wrap-no-matched-cards.f.fh
          span 該当する会員様がいません。

    div(v-if="clients && !clients[0]").wrap-no-clients.f.fh
      div
        span.mb12 会員様がいません。新しく追加してみましょう。
        div.f.fc
          v-btn(@click="$emit('openModal')" color="#2a2a2a" dark) 会員様を追加

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-clients {
  width: 93%;
  min-height: 100vh;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 48px;
  .wrap-h2 {
    position: relative;
    h2 {
      color: $primary_text_color;
      font-size: 16px;
      border-bottom: solid $primary_text_color 1px;
    }
    .add-record-button {
      position: absolute;
      right: 0;
      background: $active_color;
      border-radius: 3px;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
  .wrap-cards {
    background: $primary_bg_color;
    border-radius: 3px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    .clients-header {
      border-bottom: $basic_border;
      .wrap-input {
        width: 140px;
        // border-bottom: solid 0.8px;
        input {
          outline: none;
        }
      }
      .wrap-selector {
        // border-bottom: solid 0.8px;
        select {
          outline: none;
        }
      }
    }
    .wrap-no-matched-cards {
      width: 100%;
      height: calc(100vh - 200px);
    }
  }
  .wrap-no-clients {
    width: 100%;
    height: calc(100vh - 48px);
    span {
      display: block;
      text-align: center;
    }
  }
  .v-btn {
    margin: 0;
  }
}
</style>

<script>
import db from '@/components/utils/firebase'
import { COLLECTIONS } from '@/enums'

import { createNamespacedHelpers } from 'vuex'
import ItemClientCard from '@/components/item/ItemClientCard.vue'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapState: mapStateClient, mapActions: mapActionsClient } = createNamespacedHelpers('client')

export default {
  components: {
    ItemClientCard
  },
  props: {
  },
  data () {
    return {
      cards: [],
      myPairs: [],
      nameForSearch: '',
      filterLabel: '全て',
      filterItems: [
        '全て', '担当した会員'
      ]
    }
  },
  computed: {
    ...mapStateClient(['clients']),
    ...mapStateAuth(['uid'])
  },
  watch: {
    nameForSearch: function (newVal) {
      this.filterByTypeAndName()
      // this.cards = this.cards.filter(card => {
      //   return card.name.indexOf(newVal) > -1
      // })
    },
    filterLabel: function (newItem) {
      this.filterLabel = newItem
      this.filterByTypeAndName()
    },
    clients: function () {
      this.filterByTypeAndName()
    }
  },
  async created () {
    await this.loadClients()
    this.cards = this.clients.filter(e => { return true })

    this.myPairs = await db.collection(COLLECTIONS.PAIRS)
      .where('trainerId', '==', this.uid)
      .get()
      .then((q) => {
        return q.docs.map((d) => {
          var pair = d.data()
          pair.id = d.id
          return pair
        })
      })
  },
  methods: {
    ...mapActionsClient(['loadClients']),
    filterByTypeAndName () {
      switch (this.filterLabel) {
        case '全て':
          this.cards = this.clients.filter(e => { return true })
          break
        case '担当した会員':
          this.cards = this.clients.filter(e => {
            var myClients = this.myPairs.map(e => { return e.clientId })
            return myClients.includes(e.uid)
          })
          break
      }

      this.cards = this.cards.filter(card => {
        return card.name.indexOf(this.nameForSearch) > -1
      })

      // mixpanel.track('filterByTypeAndName', {
      //   createdAt: new Date(),
      //   type: this.filterLabel
      // })
    }
  }
}
</script>
