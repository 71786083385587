<template lang="pug">
  div(@click="$router.push(`/record/${content.uid}`)").wrap-item-client-card.f.fm.flex-between.px14.py14
    div.wrap-client-info.f.fm.flex-wrap
      div.wrap-img.f.fh.mr12
        img(:src="content.photoURL")
      div.wrap-profile
        span.name {{content.name}}
        span.time.mr8 {{`更新日時：${updatedAtStr}`}}
        // span.time {{`登録日時：${createdAtStr}`}}
    div.wrap-actions
      v-icon(@click.stop="removeClient" color="#999") delete
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-item-client-card {
  background: $primary_bg_color;
  border-bottom: $secondary_border;
  // border-radius: 3px;
  // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  //   0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  .wrap-client-info {
    .wrap-img {
      width: 72px;
      height: 72px;
      overflow: hidden;
      border: $secondary_border;
      border-radius: 50%;
      img {
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
      }
    }
    .wrap-profile {
      span {
        display: block;
      }
      .name {
        font-size: 18px;
      }
      .time {
        font-size: 14px;
        color: #999;
      }
    }
  }
}
</style>

<script>
import moment from 'moment'

import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapActions: mapActionsClient } = createNamespacedHelpers('client')

export default {
  components: {
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      createdAtStr: null,
      updatedAtStr: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
    this.createdAtStr = moment.unix(this.content.created.seconds).format('YYYY-MM-DD h:mm')
    this.updatedAtStr = moment.unix(this.content.updated.seconds).format('YYYY-MM-DD h:mm')
    if (this.createdAtStr === 'Invalid date') {
      this.createdAtStr = moment(this.content.created).format('YYYY-MM-DD h:mm')
      this.updatedAtStr = moment(this.content.updated).format('YYYY-MM-DD h:mm')
    }
  },
  methods: {
    ...mapActionsClient(['deleteClient']),
    removeClient () {
      var letDelete = confirm(`${this.content.name}のデータを削除しますか？（記録は全て失われます。）`)
      if (letDelete) this.deleteClient(this.content.uid)
      // mixpanel.track("onRemoveClient", { createdAt: new Date() })
    }
  }
}
</script>
